<template>
  <v-container class="mt-7">
    <div
      v-if="loading"
      class="d-flex flex-column align-center justify-center mt-12"
    >
      <h3 class="mb-8">Processing your request...</h3>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-row v-if="!loading" class="align-center">
      <v-col class="text-center">
        <v-card
          v-if="message"
          outlined
          max-width="600"
          elevation="12"
          class="mx-auto py-8"
        >
          <v-card-text>
            <h3>
              {{ message }}
            </h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div
      v-if="!message && !loading"
      class="mt-12 d-flex flex-column align-center justify-center"
    >
      <h1 class="mb-8 primary--text">Thank You for Connecting</h1>

      <img
        src="https://report.riskscore.us/hs-fs/hubfs/Meeting%20Request%20-%20Risk%20Score%20Blue.png?width=400&height=278&name=Meeting%20Request%20-%20Risk%20Score%20Blue.png"
        style="width: 250px"
        class="mb-8"
      />
      <p class="text-h3">Your advisor will be in touch soon!</p>
    </div>
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { updateProspect } from "@/graphql/mutations";
import { customGetProspectCtaRequests } from "@/customGraphQL/customQueries";

import getUserCredentialsMixin from "@/mixins/getUserCredentialsMixin";

export default {
  mixins: [getUserCredentialsMixin],
  data() {
    return {
      ctaRequestObj: null,
      queryParamSource: null,
      message: "",
      loading: true,
    };
  },
  async beforeRouteEnter(to, from, next) {
    // react to route changes...
    next((vm) => {
      // access to component's instance using `vm` .
      // this is done because this navigation guard is called before the component is created.
      vm.prospectId = to.params.id;
      vm.queryParamSource = to.query.source ? to.query.source : "report";
    });
  },
  async mounted() {
    this.setup();
  },
  methods: {
    async setup() {
      await this.getCredentials(this.prospectId);
      this.handleUpdateProspect(this.prospectId);
    },
    async getExistingCtaRequests(prospectId) {
      try {
        const res = await API.graphql({
          query: customGetProspectCtaRequests,
          variables: { id: prospectId },
          authMode: this.authMode,
        });

        return res.data.getProspect?.ctaRequests;
      } catch (error) {
        console.log("Error getting existing cta requests", error);
        this.message =
          "Sorry, an error has occurred. Please contact your advisor for assistance.";
      }
    },
    async handleUpdateProspect(prospectId) {
      this.loading = true;
      if (!prospectId) {
        this.loading = false;
        this.message =
          "Sorry, the request information is invalid or missing. Please contact your advisor for assistance.";
        return;
      }

      const regex = new RegExp(
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
      );

      const regexValid = regex.test(prospectId);

      if (!regexValid) {
        this.loading = false;
        this.message =
          "Sorry, the request information is invalid or missing. Please contact your advisor for assistance.";
        return;
      }

      try {
        // Check if existing requests exists
        const existingCtaRequests = await this.getExistingCtaRequests(
          prospectId
        );

        let previouslySetContactRequest;

        if (existingCtaRequests) {
          previouslySetContactRequest = existingCtaRequests.find(
            (request) => request["name"] === "report-contact-request"
          );
        }

        if (previouslySetContactRequest) {
          // This type of request already exists, just update the time and source (in case it changed)
          this.ctaRequestObj = previouslySetContactRequest;
          this.ctaRequestObj["updatedAt"] = new Date().toISOString();
          this.ctaRequestObj["source"] = this.queryParamSource;

          const previousRequestIndex = existingCtaRequests.findIndex(
            (object) => {
              return object.id === previouslySetContactRequest.id;
            }
          );

          existingCtaRequests[previousRequestIndex] = this.ctaRequestObj;
        } else {
          // New entry
          this.ctaRequestObj = {
            id: uuidv4(),
            name: "report-contact-request",
            source: this.queryParamSource,
            createdAt: new Date().toISOString(),
            updatedAt: null,
          };
        }

        const updateData = {
          id: prospectId,
          ctaRequests: existingCtaRequests
            ? [...existingCtaRequests]
            : [this.ctaRequestObj],
        };

        await API.graphql({
          query: updateProspect,
          variables: { input: updateData },
          authMode: this.authMode,
        });
      } catch (error) {
        console.log("Error updating prospect...", error);
        this.message =
          "Sorry, an error has occurred. Please contact your advisor for assistance.";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
